<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">后台管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 10px 0px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" size="mini" @click="addDialogVisible = true">+添加角色</el-button>
        </el-col>
      </el-row>
      <el-table size="mini" :data="roleList" border style="margin-top: 10px">
        <!-- 添加展开列 -->
        <!-- <el-table-column type="expand"></el-table-column> -->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="角色名称" prop="roleName"></el-table-column>
        <el-table-column label="角色描述" prop="description"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row.id)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="remove(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加角色对话框 -->
    <el-dialog title="添加角色" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <el-form :model="addForm" ref="addFormRef" size="mini" label-width="100px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="addForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改角色的对话框 -->
    <el-dialog title="修改角色" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" size="mini" label-width="70px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="editForm.roleName" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roleList: [],
      addDialogVisible: false, // 添加菜单对话框
      addForm: {},
      editDialogVisible: false, // 编辑菜单对话框
      editForm: {},
    };
  },
  computed:{
    username(){
      return this.$store.state.permission.user.username;
    } 
  },
  created() {
    this.getRoleList();
  },
  methods: {
    // 角色列表
    async getRoleList() {
      const { data: res } = await this.$api.getRoleList();
      this.roleList = res.data;
    },
    // 添加角色
    async addRole() {
      this.addForm.username = this.username
      const { data: res } = await this.$api.addRole(this.addForm);
      this.addDialogVisible = false;
      this.getRoleList();
    },
    addDialogClosed() {
      //对话框关闭之后，重置表单
      this.$refs.addFormRef.resetFields();
    },
    // 显示编辑角色信息
    async showEditDialog(id) {
      const { data: res } = await this.$api.getRoleById(id);
      this.editForm = res.data;
      this.editDialogVisible = true;
    },
    // 编辑角色
    async editRole() {
      const { data: res } = await this.$api.editRole(
        this.editForm.id,
        this.editForm
      );
      // 隐藏添加对话框
      this.editDialogVisible = false;
      this.getRoleList();
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 删除角色
    async remove(id) {
      const result = await this.$confirm("是否删除该角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteRole(id);
      this.getRoleList();
    }
  }
};
</script>